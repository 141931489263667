const convertToTimeFromDateOnly = (dateTime) => (dateTime ? new Date(dateTime.split('T')[0]).getTime() : null);

export const mapParentDocumentToMissingEvent = (missingEvent, documents) => {
  return {
    ...missingEvent,
    context: {
      ...missingEvent.context,
      issueDate:
        missingEvent.context.issueDate &&
        convertToTimeFromDateOnly(new Date(missingEvent.context.issueDate).toISOString()),
    },
    parentDocument:
      missingEvent.parentDocumentId && documents.find((document) => document.id === missingEvent.parentDocumentId),
  };
};

export const setDocumentFromSource = (event, documents) => {
  const eventDocRef = event.eventReferences ? event.eventReferences[0]?.documentId : event.source?.ref;
  const document = documents.find((doc) => doc.id === eventDocRef);
  if (document)
    return {
      ...event,
      document: {
        id: document.id,
        type: document.type,
        documentNumber: document.documentNumber,
      },
    };

  return event;
};
