import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useSupplierActivity(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(ACTIVITY_QUERY, variables);
  const events = computed(() => ({
    billings: result.value?.billings?.nodes ?? [],
    orders: result.value?.orders?.nodes ?? [],
  }));

  onError((err) => {
    console.error('Issues modal - useSupplierActivity', err);
    error();
  });

  return {
    billings: computed(() => events.value.billings),
    orders: computed(() => events.value.orders),
    refetch,
    loading,
  };
}

const ACTIVITY_QUERY = gql`
  query getActivity($businessId: ID!, $supplierId: ID, $fromDate: ISODate, $toDate: ISODate) {
    billings(businessId: $businessId, supplierId: $supplierId, fromDate: $fromDate, toDate: $toDate) {
      nodes {
        id
        date
        netAmount
        taxAmount
        type
        totalAmount
        orderLinks {
          amount
          order {
            id
            source {
              ref
            }
          }
        }
        deliveryRefs {
          delivery {
            id
          }
        }
        imbalances {
          orderedAmount
          billedAmount
        }
        source {
          ref
        }
      }
    }

    orders(businessId: $businessId, supplierId: $supplierId, fromDate: $fromDate, toDate: $toDate) {
      nodes {
        id
        date
        amount
        products {
          id
          discount
          price
          quantity
        }
        differences {
          customerItemId
          amount
          pricingAmount
          quantityAmount
          product {
            id
            name
            sku
          }
          quantity {
            supplierValue
            customerValue
            solved
          }
          price {
            supplierValue
            customerValue
            solved
          }
          discount {
            supplierValue
            customerValue
            solved
          }
          netPrice {
            supplierValue
            customerValue
            solved
          }
        }
        source {
          ref
        }
        eventReferences {
          documentId
        }
      }
    }
  }
`;
