import { setDocumentFromSource } from './issuesModal';

export const prepareIssuesBillings = (billings, orders, documents) =>
  billings.map((billing) => {
    const billingWithLinkedEvents = {
      ...billing,
      orderLinks: billing.orderLinks.map((ol) => ({
        ...ol,
        order: orders.find((o) => o.id === ol.order?.id) ?? ol.order,
      })),
    };
    return setDocumentFromSource(billingWithLinkedEvents, documents);
  });
