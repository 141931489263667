import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification } from '@/modules/core';

export function useSupplierActivityDocuments(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(ACTIVITY_DOCUMENTS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId,
  }));
  const documentConnection = useResult(result, { nodes: [], totalCount: 0 });
  const documents = computed(() => documentConnection.value.nodes);

  onError((err) => {
    console.error('Issues modal - useSupplierActivityDocuments', err);
    error();
  });

  return {
    documents,
    refetch,
    loading,
  };
}

const ACTIVITY_DOCUMENTS_QUERY = gql`
  query getActivityDocuments($businessId: ID!, $supplierId: ID, $fromDate: ISODate, $toDate: ISODate) {
    documentsNew2(
      businessId: $businessId
      supplierId: $supplierId
      fromDate: $fromDate
      toDate: $toDate
      recorded: true
    ) {
      nodes {
        id
        issueDate
        supplierId
        documentNumber
        type
        references {
          documentNumber
          issueDate
          netAmount
          totalAmount
          debitAmount
          creditAmount
        }
        items {
          reference
        }
      }
    }
  }
`;
