import i18n from '@/imports/startup/client/i18n';
import { currency } from '@/locale/numberConfig';
import { options } from '@/locale/dateConfig';

export const dateFormat = new Intl.DateTimeFormat(i18n.locale)
  .formatToParts()
  .map(({ type, value }) => {
    switch (type) {
      case 'day':
        return 'd';
      case 'month':
        return 'm';
      case 'year':
        return 'yyyy';
      default:
        return value;
    }
  })
  .join('');

export const formatDate = (ms) => {
  return ms ? new Date(ms).toLocaleDateString(i18n.locale, options.short) : i18n.t('commons.unknownDate');
};

export const formatToFloat = (value) => {
  return typeof value === 'number' && !Number.isNaN(value) ? value / 100 : null;
};

export const formatMoney = (value) => {
  return typeof value === 'number' && !Number.isNaN(value) ? value.toLocaleString(i18n.locale, currency) : null;
};
