import i18n from '@/imports/startup/client/i18n';
import ExcelJS from 'exceljs';
import { isEmpty, reject, isNil, flatten } from 'ramda';
import FileSaver from 'file-saver';

import { getCurrencySymbol } from '@/modules/purchase-management/purchaseManagementFormatters';
import { dateFormat } from '../tools/formatters';

const defaultFontName = 'Calibri';

const transformData = ({ unbilled, billed, missing }) => {
  const billings = (billed || []).map((billing) =>
    reject(isNil)({
      date: new Date(billing.date),
      reference: billing.documentNumber,
      netAmount: billing.netAmount,
      taxAmount: billing.taxAmount,
      totalAmount: billing.totalAmount,
      status: i18n.t(`modals.issues.excel.status.${billing.status}`),
      description: billing.details ? `${billing.details.text} (${billing.details.info})` : null,
      supplier: billing.supplier?.name,
    })
  );

  const unbilledOrders = (unbilled || []).map((unbilledOrder) =>
    reject(isNil)({
      date: new Date(unbilledOrder.date),
      reference: unbilledOrder.documentNumber,
      netAmount: unbilledOrder.netAmount,
      status: i18n.t(`modals.issues.excel.status.${unbilledOrder.status}`),
      description: unbilledOrder.details ? `${unbilledOrder.details.text} (${unbilledOrder.details.info})` : null,
      supplier: unbilledOrder.supplier?.name,
    })
  );

  const invoicesWithIssues = flatten(
    (billed || []).map((billing) =>
      billing.orderItems.map((orderItem) =>
        reject(isNil)({
          billingDate: new Date(billing.date),
          billingReference: billing.documentNumber,
          orderDate: new Date(orderItem.orderDate),
          orderReference: orderItem.documentNumber,
          sku: orderItem.sku,
          productName: orderItem.product,
          orderTerms: orderItem.terms,
          differenceType: orderItem.differenceType,
          differenceAmount: orderItem.differenceAmount,
          description: orderItem.details,
          supplier: billing.supplier?.name,
        })
      )
    )
  );

  const unbilledOrdersWithDifferences = flatten(
    (unbilled || []).map((unbilledOrder) =>
      unbilledOrder.orderItems.map((orderItem) =>
        reject(isNil)({
          orderDate: new Date(orderItem.orderDate),
          orderReference: orderItem.documentNumber,
          sku: orderItem.sku,
          productName: orderItem.product,
          orderTerms: orderItem.terms,
          differenceType: orderItem.differenceType,
          differenceAmount: orderItem.differenceAmount,
          description: orderItem.details,
          supplier: unbilledOrder.supplier?.name,
        })
      )
    )
  );

  const missingDocuments = (missing || []).map((missingDocument) =>
    reject(isNil)({
      date: missingDocument.date ? new Date(missingDocument.date) : i18n.t('commons.unknownDate'),
      type: missingDocument.documentType,
      documentNumber: missingDocument.documentName,
      totalAmount: missingDocument.totalAmount,
      description: missingDocument.details,
      supplier: missingDocument.supplier?.name,
    })
  );

  return { billings, unbilledOrders, invoicesWithIssues, unbilledOrdersWithDifferences, missingDocuments };
};

export default async ({ unbilled, billed, missing }, date, isSupplierData) => {
  const workbook = new ExcelJS.Workbook();
  const direction = i18n.direction();

  const { billings, unbilledOrders, invoicesWithIssues, unbilledOrdersWithDifferences, missingDocuments } =
    transformData({ unbilled, billed, missing });

  const invoicesStatusSheetMetadata = getInvoicesStatusSheetMetadata(direction, isSupplierData);
  createInvoicesStatusSheet(workbook, invoicesStatusSheetMetadata, billings, unbilledOrders, date);

  const issuesSheetMetadata = getIssuesSheetMetadata(direction, isSupplierData);
  createIssuesSheet(workbook, issuesSheetMetadata, invoicesWithIssues, unbilledOrdersWithDifferences, date);

  const missingDocumentsSheetMetadata = getMissingDocumentsSheetMetadata(direction, isSupplierData);
  createMissingDocumentsSheet(workbook, missingDocumentsSheetMetadata, missingDocuments, date);

  return workbook.xlsx.writeBuffer().then((buffer) => {
    return FileSaver.saveAs(new Blob([buffer]), `${i18n.t('modals.issues.excel.filename', { date })}.xlsx`);
  });
};

const addTableTitle = (worksheet, title) => {
  const row = worksheet.addRow();
  const cell = row.getCell(1);
  cell.value = title;
  cell.font = {
    name: defaultFontName,
    size: 13,
    bold: true,
  };
  worksheet.addRow();
};

const addTableHeaders = (worksheet, headers) => {
  const row = worksheet.addRow(headers);
  row.font = { name: defaultFontName, size: 12, bold: true };
};

const setColumnWidths = (worksheet) => {
  worksheet.columns.forEach((column) => {
    column.width = Math.max(12, ...column.values.filter((v) => v.length).map((v) => v.length));
  });
};

const createSheetTable = (worksheet, tableHeaders, title, data) => {
  if (title) addTableTitle(worksheet, title);
  addTableHeaders(worksheet, tableHeaders);

  data.forEach((rowData) => worksheet.addRow(rowData));
  worksheet.addRow();
};

const getInvoicesStatusSheetMetadata = (direction, isSupplierData) => ({
  direction,
  columns: [
    ...(!isSupplierData ? [{ key: 'supplier', label: i18n.t('modals.issues.excel.commonTableHeaders.supplier') }] : []),
    {
      key: 'date',
      label: i18n.t('modals.issues.excel.commonTableHeaders.date'),
    },
    {
      key: 'reference',
      label: i18n.t('modals.issues.excel.sheets.invoicesStatus.tableHeaders.reference'),
    },
    {
      key: 'netAmount',
      label: i18n.t('modals.issues.excel.sheets.invoicesStatus.tableHeaders.netAmount'),
    },
    {
      key: 'taxAmount',
      label: i18n.t('modals.issues.excel.sheets.invoicesStatus.tableHeaders.taxAmount'),
    },
    {
      key: 'totalAmount',
      label: i18n.t('modals.issues.excel.commonTableHeaders.totalAmount'),
    },
    {
      key: 'status',
      label: i18n.t('modals.issues.excel.commonTableHeaders.status'),
    },
    {
      key: 'description',
      label: i18n.t('modals.issues.excel.commonTableHeaders.description'),
    },
  ],
});

const getIssuesSheetMetadata = (direction, isSupplierData) => ({
  direction,
  columns: [
    ...(!isSupplierData ? [{ key: 'supplier', label: i18n.t('modals.issues.excel.commonTableHeaders.supplier') }] : []),
    {
      key: 'billingDate',
      label: i18n.t('modals.issues.excel.sheets.issues.tableHeaders.billingDate'),
    },
    {
      key: 'billingReference',
      label: i18n.t('modals.issues.excel.sheets.issues.tableHeaders.billingReference'),
    },
    {
      key: 'orderDate',
      label: i18n.t('modals.issues.excel.sheets.issues.tableHeaders.orderDate'),
    },
    {
      key: 'orderReference',
      label: i18n.t('modals.issues.excel.sheets.issues.tableHeaders.orderReference'),
    },
    {
      key: 'sku',
      label: i18n.t('modals.issues.excel.sheets.issues.tableHeaders.sku'),
    },
    {
      key: 'productName',
      label: i18n.t('modals.issues.excel.sheets.issues.tableHeaders.productName'),
    },
    {
      key: 'orderTerms',
      label: i18n.t('modals.issues.excel.sheets.issues.tableHeaders.orderTerms'),
    },
    {
      key: 'differenceType',
      label: i18n.t('modals.issues.excel.sheets.issues.tableHeaders.differenceType'),
    },
    {
      key: 'differenceAmount',
      label: i18n.t('modals.issues.excel.sheets.issues.tableHeaders.differenceAmount'),
    },
    {
      key: 'description',
      label: i18n.t('modals.issues.excel.commonTableHeaders.description'),
    },
  ],
});

const getMissingDocumentsSheetMetadata = (direction, isSupplierData) => ({
  direction,
  columns: [
    ...(!isSupplierData ? [{ key: 'supplier', label: i18n.t('modals.issues.excel.commonTableHeaders.supplier') }] : []),
    {
      key: 'date',
      label: i18n.t('modals.issues.excel.commonTableHeaders.date'),
    },
    {
      key: 'type',
      label: i18n.t('modals.issues.excel.sheets.missingDocuments.tableHeaders.type'),
    },
    {
      key: 'documentNumber',
      label: i18n.t('modals.issues.excel.sheets.missingDocuments.tableHeaders.documentNumber'),
    },
    {
      key: 'totalAmount',
      label: i18n.t('modals.issues.excel.commonTableHeaders.totalAmount'),
    },
    {
      key: 'description',
      label: i18n.t('modals.issues.excel.sheets.missingDocuments.tableHeaders.description'),
    },
    {
      key: 'status',
      label: i18n.t('modals.issues.excel.commonTableHeaders.status'),
    },
  ],
});

const createInvoicesStatusSheet = (workbook, metadata, invoices, unbilledOrders, date) => {
  const worksheet = workbook.addWorksheet(i18n.t('modals.issues.excel.sheets.invoicesStatus.name', { date }), {
    views: [{ rightToLeft: true }],
  });

  const alignment = {
    horizontal: metadata.direction === 'rtl' ? 'right' : 'left',
    readingOrder: metadata.direction,
  };

  worksheet.columns = metadata.columns.map((col) => {
    const style = { alignment };
    if (['totalAmount', 'taxAmount', 'netAmount'].includes(col.key))
      style.numFmt = `[$${getCurrencySymbol()}-40D]#,##0.00`;
    else if (col.key === 'date') style.numFmt = dateFormat;

    return reject(isEmpty)({ key: col.key, style });
  });

  const tableHeaders = metadata.columns.map((column) => column.label);

  createSheetTable(
    worksheet,
    tableHeaders,
    i18n.t('modals.issues.excel.sheets.invoicesStatus.titles.invoices', { date }),
    invoices
  );
  createSheetTable(
    worksheet,
    tableHeaders,
    i18n.t('modals.issues.excel.sheets.invoicesStatus.titles.unbilledOrders'),
    unbilledOrders
  );

  const statusColumnIndex = metadata.columns.findIndex((columnData) => columnData.key === 'status');
  formatStatusCells(worksheet, statusColumnIndex);
  setColumnWidths(worksheet);
};

const createIssuesSheet = (workbook, metadata, invoicesWithIssues, unbilledOrdersDifferences, date) => {
  const worksheet = workbook.addWorksheet(i18n.t('modals.issues.excel.sheets.issues.name', { date }), {
    views: [{ rightToLeft: true }],
  });

  const alignment = {
    horizontal: metadata.direction === 'rtl' ? 'right' : 'left',
    readingOrder: metadata.direction,
  };

  worksheet.columns = metadata.columns.map((col) => {
    const style = { alignment };
    if (col.key === 'differenceAmount') style.numFmt = `[$${getCurrencySymbol()}-40D]#,##0.00`;
    else if (['billingDate', 'orderDate'].includes(col.key)) style.numFmt = dateFormat;

    return reject(isEmpty)({ key: col.key, style });
  });

  const tableHeaders = metadata.columns.map((column) => column.label);

  createSheetTable(
    worksheet,
    tableHeaders,
    i18n.t('modals.issues.excel.sheets.issues.titles.invoicesWithIssues'),
    invoicesWithIssues
  );
  createSheetTable(
    worksheet,
    tableHeaders,
    i18n.t('modals.issues.excel.sheets.issues.titles.differenceInUnbilledOrders'),
    unbilledOrdersDifferences
  );
  setColumnWidths(worksheet);
};

const createMissingDocumentsSheet = (workbook, metadata, missingDocuments, date) => {
  const worksheet = workbook.addWorksheet(i18n.t('modals.issues.excel.sheets.missingDocuments.name', { date }), {
    views: [{ rightToLeft: true }],
  });

  const alignment = {
    horizontal: metadata.direction === 'rtl' ? 'right' : 'left',
    readingOrder: metadata.direction,
  };

  worksheet.columns = metadata.columns.map((col) => {
    const style = { alignment };
    if (col.key === 'totalAmount') style.numFmt = `[$${getCurrencySymbol()}-40D]#,##0.00`;
    else if (col.key === 'date') style.numFmt = dateFormat;

    return reject(isEmpty)({ key: col.key, style });
  });

  const tableHeaders = metadata.columns.map((column) => column.label);

  createSheetTable(worksheet, tableHeaders, null, missingDocuments);
  setColumnWidths(worksheet);
};

const formatStatusCells = (worksheet, statusColumnIndex) => {
  const approvedTranslation = i18n.t('modals.issues.excel.status.approved');
  const notApprovedTranslation = i18n.t('modals.issues.excel.status.notApproved');
  worksheet.eachRow((row) => {
    const cell = row.getCell(statusColumnIndex + 1);
    if (cell.value !== approvedTranslation && cell.value !== notApprovedTranslation) return;
    cell.style = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: cell.value === approvedTranslation ? 'FF92D050' : 'FFFF0000',
        },
        bgColor: { indexed: 64 },
      },
    };
  });
};
