import { useQuery, useResult } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';

import { useNotification } from '@/modules/core';

const PAYMENT_DIFFERENCES_QUERY = gql`
  query paymentDifferences($customerId: ID!) {
    paymentDifferences(customerId: $customerId) {
      supplierPayment {
        id
        customerId
        supplierId
        date
        amount
        isRefund
        eventReferences {
          reference
          documentId
          document {
            id
            type
            documentNumber
          }
        }
        transactions {
          id
          paymentMethod
        }
      }
      customerPayment {
        type
      }
    }
  }
`;

export const usePaymentDifferences = (variables) => {
  const { error } = useNotification();
  const { result, loading, onError, refetch } = useQuery(PAYMENT_DIFFERENCES_QUERY, variables, () => ({
    enabled: !!variables.value.customerId,
  }));
  const paymentDifferences = useResult(result, []);

  onError((err) => {
    console.error('issuesModal - usePaymentDifferences', err);
    error();
  });

  return { paymentDifferences, loading, refetch };
};
