import { DateTime } from 'luxon';

import {
  CONSOLIDATED_DOCS,
  INVOICE_DOCS,
  RECONCILIATION_DOCS,
  NOTE_DOCS,
  DOCUMENT_TYPES,
} from '@/modules/document/types';
import { mapParentDocumentToMissingEvent } from './issuesModal';

export const prepareIssuesMissingDocuments = (missingEvents, supplierId, documents, month) =>
  missingEvents
    .map((missingEvent) => mapParentDocumentToMissingEvent(missingEvent, documents, month))
    .filter((missingEvent) => {
      const isRelatedToSupplier = missingEvent.supplierId === supplierId;
      if (!isRelatedToSupplier) {
        return false;
      }
      if (
        !missingEvent.possibleTypes.some((type) =>
          [...CONSOLIDATED_DOCS, ...INVOICE_DOCS, ...NOTE_DOCS, DOCUMENT_TYPES.INVOICE_RECEIPT].includes(type)
        )
      ) {
        return false;
      }

      if (missingEvent.estimatedDate)
        return (
          DateTime.fromISO(missingEvent.estimatedDate).month === DateTime.fromISO(month).month &&
          DateTime.fromISO(missingEvent.estimatedDate).year === DateTime.fromISO(month).year
        );

      if (missingEvent.parentDocument) {
        const parentDocument = missingEvent.parentDocument;
        const isParentConsolidated = CONSOLIDATED_DOCS.includes(parentDocument.type);
        const isParentInvoiceWithRefs =
          [...INVOICE_DOCS, DOCUMENT_TYPES.INVOICE_RECEIPT].includes(parentDocument.type) &&
          checkDocRefs(parentDocument);
        const isParentReconciliation = RECONCILIATION_DOCS.includes(parentDocument.type);
        const isParentReceiptWithRefs = parentDocument.type === DOCUMENT_TYPES.RECEIPT && checkDocRefs(parentDocument);
        const documentIssueDate = DateTime.fromJSDate(new Date(parentDocument.issueDate), {
          zone: 'UTC',
        }).startOf('month');
        const currentSelectionDate = DateTime.local(
          parseInt(month.split('-')[0]),
          parseInt(month.split('-')[1])
        ).startOf('month');

        const parentDocumentMonthDiff = Math.round(
          documentIssueDate.diff(currentSelectionDate, 'months').toObject().months
        );

        return (
          (isParentReconciliation && parentDocumentMonthDiff === 1) ||
          (isParentConsolidated && parentDocumentMonthDiff === 0) ||
          (isParentReceiptWithRefs && parentDocumentMonthDiff === 1) ||
          (isParentInvoiceWithRefs && parentDocumentMonthDiff === 0)
        );
      }

      return false;
    });

const checkDocRefs = (document) => {
  const itemsReferences = document.items?.filter(({ reference }) => reference);
  return Boolean(document.references?.length || itemsReferences?.length);
};
